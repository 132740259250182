import axios from 'axios';
import {showMessage} from 'app/store/actions/fuse';
import { API_SERVER_URL } from 'app/main/config';

export const GET_WALL_COLOR = '[WALL_COLOR] GET WALL COLOR';
export function getWallColor() {
    return (dispatch => {
        axios.post(`${API_SERVER_URL}/api/wallColor/read/wall_color`)
        .then(response => {
            const {wallColor} = response.data;
                dispatch({
                    type:GET_WALL_COLOR,
                    wallColor: wallColor,
                })
        }).catch(err=>{
            dispatch(showMessage({
                message         : 'Failed to load WALL COLOR data',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));
            console.log("err", err);
        });    
    })
}

export const ADD_WALL_COLOR = '[WALL_COLOR] ADD WALL COLOR';
export function addWallColor(data) {

    var newData = {
        name: data.name,
        color : data.color,
    }
       
    return ((dispatch) => {
        const request = axios.post(`${API_SERVER_URL}/api/wallColor/insert/wall_color`, { ...newData });
        request.then(response => {
            const {success, wallColor} = response.data;
            if(success === true) {
                dispatch(showMessage({
                    message         : 'Successfully Inserted',
                    autoHideDuration: 2000,
                    anchorOrigin    : {
                        vertical  : 'top',
                        horizontal: 'right'
                    },variant : 'success'
                }));
                Promise.all([
                    dispatch({
                        type:ADD_WALL_COLOR,
                        wallColor: wallColor,
                    })
                ])
            }
        })
        .catch(err => {
            dispatch(showMessage({
                message         : 'Failed to add WALL COLOR data',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));

            console.log("err", err);
        });
    });
}

export const DELETE_WALL_COLOR = '[WALL_COLOR] DELETE WALL COLOR';
export function deleteWallColor(record) {
    return ((dispatch) => {
        axios.post(`${API_SERVER_URL}/api/wallColor/remove/wall_color`, {
            id: record.id
        })
        .then(response => {
            const {success} = response.data;
            if(success === true) {
                dispatch(showMessage({
                    message         : 'Successfully Deleted',
                    autoHideDuration: 2000,
                    anchorOrigin    : {
                        vertical  : 'top',
                        horizontal: 'right'
                    },variant : 'success'
                }));
                Promise.all([
                    dispatch({
                        type: DELETE_WALL_COLOR
                    })
                ]).then(() => dispatch(getWallColor()))
            }
        }).catch(err => {
            dispatch(showMessage({
                message         : 'Failed to delete WALL COLOR data',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));

            console.log("err", err);
        });
    })
}

export const UPDATE_WALL_COLOR = '[WALL_COLOR] UPDATE WALL COLOR';
export function updateWallColor(data, id, guid) {
    var updateData = {
        id        : id,
        name      : data.name,
        color     : data.color,
    }

    // const formData = new FormData();
    // formData.append("thumbnail",data.thumbnail[0].originFileObj);
    // data.gltf.forEach(fElement => {
    //     formData.append("gltf",fElement.originFileObj);
    // })

    return ((dispatch) => {
        const request = axios.post(`${API_SERVER_URL}/api/wallColor/update/wall_color`, { ...updateData });
        request.then(response => {
            const {success, wallColor} = response.data;
            if(success === true) {
                dispatch(showMessage({
                    message         : 'Successfully Updated',
                    autoHideDuration: 2000,
                    anchorOrigin    : {
                        vertical  : 'top',
                        horizontal: 'right'
                    },variant : 'success'
                }));
                Promise.all([
                    dispatch({
                        type:UPDATE_WALL_COLOR,
                        wallColor: wallColor,
                    })
                ])
            }
        }).catch(err => {
            dispatch(showMessage({
                message         : 'Failed to update WALL COLOR data',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));

            console.log("err", err);
        });
    })
}
export const SET_WALL_COLOR_SORT_ORDER = '[WALL_COLOR] SET SORT ORDER';
export function setSortOrder(data) {
    return ((dispatch) => {
        axios.post(`${API_SERVER_URL}/api/base/setSortOrder`, {
            id: data.id,
            sort_order: data.sort_order,
            table_name: 'wall_color'
        })
        .then(response => {
            const {success} = response.data;
            if(success === true) {
                Promise.all([
                    dispatch({
                        type:SET_WALL_COLOR_SORT_ORDER,
                    })  
                ]).then(() => dispatch(getWallColor()))
            } else {
            }
        })
        .catch(err => {
            dispatch(showMessage({
                message         : 'Failed to set sort order',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));
            console.log("failed to set sort order in door style.", err);
        })
    })
}