import axios from 'axios';
import { showMessage } from 'app/store/actions/fuse';
import { API_SERVER_URL, jsonArray, shapeSVGValue, getData, uploadFiles, putFiles, deleteFiles, canUpload } from 'app/main/config';
import { uploadLoading }from 'app/store/actions/fuse';

export const GET_CABINET_CATEGORY = '[CABINET] GET CABINET CATEGORY';
export const GET_CABINET = '[CABINET] GET CABINET';
export const ADD_CABINET = '[CABINET] ADD CABINET';
export const DELETE_CABINET = '[CABINET] DELETE CABINET';
export const UPDATE_CABINET = '[CABINET] UPDATE CABINET';
export const SET_MODAL_DATA = '[CABINET] SET_MODAL_DATA';
export const SET_PUBLISH_FLAG = '[CABINET] SET PUBLISH FLAG';
export const SET_ACTIVE_FLAG = '[CABINET] SET ACTIVE FLAG';

export function setPublishedFlag(id, value) {
    return (dispatch) => {
        dispatch(uploadLoading(true));
        axios.post(`${API_SERVER_URL}/api/cabinet/published`, {
            id: id,
            value: value === true ? 1 : 0,
        })
        .then(response => {
            dispatch(uploadLoading(false));
            const {success} = response.data;
            if(success === true) {
                dispatch({
                    type: SET_PUBLISH_FLAG
                })
            }
        })
        .catch(err => {
            dispatch(uploadLoading(false));
            console.log("err", err);
        })
    }
}

export function setActiveFlag(id, value) {
    return (dispatch) => {
        dispatch(uploadLoading(true));
        axios.post(`${API_SERVER_URL}/api/cabinet/active`, {
            id: id,
            value: value === true ? 1 : 0,
        })
        .then(response => {
            dispatch(uploadLoading(false));
            const {success} = response.data;
            if(success === true) {
                dispatch({
                    type: SET_ACTIVE_FLAG
                })
            }
        })
        .catch(err => {
            dispatch(uploadLoading(false));
            console.log("err", err);
        })
    }
}


export function getCabinetCategory() {
    return (dispatch => {
        axios.post(`${API_SERVER_URL}/api/cabinetCategory/read/cabinet_category`)
        .then(response => {
            const {cabinetCategory, success} = response.data;
            if(success === true) {                
                var Category = getData(cabinetCategory, 'parent_category_id', 0);
                dispatch({
                    type:GET_CABINET_CATEGORY,
                    cabinetCategory: Category,
                })
            }
        })    
    })
}

export function getCabinet() {
    return (dispatch => {
        axios.post(`${API_SERVER_URL}/api/cabinet/read/cabinet`)
        .then(response => {
            const {cabinet} = response.data;
            dispatch({
                type:GET_CABINET,
                cabinet: cabinet,
            })
        }).catch(err=>{
            dispatch(showMessage({
                message         : 'Failed to load CABINET data',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));
            console.log("err", err);
        });    
    })
}

export function addCabinet(data) {
    var data_gltf = {
        'gltf' : jsonArray(data.gltf)
    };
    var data_string = JSON.stringify(data_gltf);
    var getArray = (data) => {
        var items = '';
        data.forEach((fElement,index) => {
            if(index === 0)
                items += fElement;
            else
                items += ',' + fElement;
        });
        return items;
    }
    var newData = {
        name: data.cabinet_name,
        long_name: data.cabinet_long_name,
        cabinet_type: data.cabinet_type,
        location_type: data.location_type,
        is_corner: data.is_corner,
        sku_number: data.sku_number,
        cabinet_category_id: data.category_id,
        width: data.width,
        height: data.height,
        length : data.length,
        leftBlindLength: data.leftBlindLength,
        rightBlindLength: data.rightBlindLength,
        comments : data.comments,
        cabinet_door_style_ids :  getArray(data.cabinet_door_style_id),
        thumbnail: data.thumbnail[0].name,
        shape_svg: shapeSVGValue(data.shape_svg),
        outline: shapeSVGValue(data.outline),
        gltf : data_string
    }

    return ((dispatch) => {
        axios.post(`${API_SERVER_URL}/api/cabinet/insert/cabinet`, {
            ...newData
        })
        .then(res => {
            dispatch(uploadLoading(true));
            if(process.env.REACT_APP_MODE === "production") {
                uploadFiles(data.gltf ? data.gltf.filter(file => file.originFileObj !== undefined) : [], `uploads/Cabinet/${res.data.data.guid + '_' + res.data.data.id}/gltf/`).then(gltf => {
                    uploadFiles(data.thumbnail.filter(file => file.originFileObj !== undefined), `uploads/Cabinet/${res.data.data.guid + '_' + res.data.data.id}/thumbnail/`).then(thumbnail => {
                        uploadFiles(data.shape_svg.filter(file => file.originFileObj !== undefined), `uploads/Cabinet/${res.data.data.guid + '_' + res.data.data.id}/shape_svg/`).then(shape_svg => {
                            uploadFiles(data.outline.filter(file => file.originFileObj !== undefined), `uploads/Cabinet/${res.data.data.guid + '_' + res.data.data.id}/outline/`).then(outline => {
                                axios.post(`${API_SERVER_URL}/api/base/updateUploadData`, 
                                {
                                    id: res.data.data.id,
                                    tableName: "cabinet",
                                    data: [ 
                                        {
                                            fieldName: 'gltf',
                                            type: 'multi',
                                            data: [ 
                                                {
                                                    jsonFieldName: 'gltf',
                                                    data: gltf,
                                                }
                                            ]
                                        }, 
                                        {
                                            fieldName: 'thumbnail',
                                            type: 'single',
                                            data: thumbnail
                                        }, 
                                        {
                                            fieldName: 'shape_svg',
                                            type: 'single',
                                            data: shape_svg
                                        },
                                        {
                                            fieldName: 'outline',
                                            type: 'single',
                                            data: outline
                                        },
                                    ]
                                }
                                )
                                .then(response => {
                                    const {success} = response.data;
                                    dispatch(uploadLoading(false));
                                    if(success === true) {

                                        dispatch(showMessage({
                                            message         : 'Successfully Inserted',
                                            autoHideDuration: 2000,
                                            anchorOrigin    : {
                                                vertical  : 'top',
                                                horizontal: 'right'
                                            },variant : 'success'
                                        }));
                                        Promise.all([
                                            dispatch({
                                                type: ADD_CABINET
                                            })
                                        ]).then(() => dispatch(getCabinet()))
                                    } else {
                                        console.log(success);
                                    }
                                })
                                .catch(err => {
                                    console.log("failed to save uploaded data in insert action", err);
                                    throw err;
                                })  
                            })
                            .catch(err => {
                                console.log("failed to upload cabinet shape(svg). ", err);
                                return;
                            })
                        })
                        .catch(err => {
                            console.log("failed to upload cabinet thumbnail. ", err);
                            return;
                        })
                    })
                    .catch(err => {
                        console.log("failed to upload cabinet gltfs. ", err);
                        return;
                    })
                })
                .catch(err => {
                    console.log("failed to upload cabinet gltfs. ", err);
                    return;
                })
            }
            else {
                const formData = new FormData();
                if(data.thumbnail[0].originFileObj !== undefined) {
                    formData.append("thumbnail", data.thumbnail[0].originFileObj);
                }
                if(canUpload(data.shape_svg)) {
                    data.shape_svg.forEach(fElement => {
                        formData.append("shape_svg", fElement.originFileObj);
                    })
                }
                if(canUpload(data.outline)){
                    formData.append("outline", data.outline[0].originFileObj);
                }
                if(canUpload(data.gltf)) {
                    data.gltf && data.gltf.forEach(fElement => {
                        formData.append("gltf",fElement.originFileObj);
                    })
                }
                formData.append("guid" , res.data.data.guid);
                formData.append("id" , res.data.data.id);
                axios.post(`${API_SERVER_URL}/api/cabinet/upload`, formData)
                .then(response => {
                    dispatch(uploadLoading(false));
                    const {success} = response.data;
                    if(success === true) {
                        axios.post(`${API_SERVER_URL}/uploads/clear`)
                        .then(res => {
                            if(res.data.success === true ){
                                dispatch(showMessage({
                                    message         : 'Successfully Inserted',
                                    autoHideDuration: 2000,
                                    anchorOrigin    : {
                                        vertical  : 'top',
                                        horizontal: 'right'
                                    },variant : 'success'
                                }));
                                Promise.all([
                                    dispatch({
                                        type: ADD_CABINET
                                    })
                                ]).then(() => dispatch(getCabinet()))
                            }
                        })
                    }
                })
            }
        }).catch(err => {
            console.log(err);
            dispatch(showMessage({
                message         : 'Failed to add CABINET data',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));
        });
    })

}

export function deleteCabinet(record) {
    return ((dispatch) => {
        var p1 = new Promise((resolve, reject) => {
            dispatch(uploadLoading(true));
            if(process.env.REACT_APP_MODE === "production" ) {
                const {gltf} = JSON.parse(record.gltf);
                gltf.push(record.thumbnail);
                if (record.shape_svg) gltf.push(record.shape_svg);
                deleteFiles(gltf).then(res => {
                    resolve("success");
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                })
            } else {
                resolve("success");
            }
        })
        p1.then(p1Value => {
            axios.post(`${API_SERVER_URL}/api/cabinet/remove/cabinet`, {
                id: record.id,
                cabinet_door_style_ids: record.cabinet_door_style_ids,
            })
            .then(response => {
                const {success} = response.data;
                dispatch(uploadLoading(false));
                if(success === true) {
                    dispatch(showMessage({
                        message         : 'Successfully Deleted',
                        autoHideDuration: 2000,
                        anchorOrigin    : {
                            vertical  : 'top',
                            horizontal: 'right'
                        },variant : 'success'
                    }));
                    Promise.all([
                        dispatch({
                            type: DELETE_CABINET
                        })
                    ]).then(() => dispatch(getCabinet()))
                }
            }).catch(err => {
                dispatch(uploadLoading(false));
                dispatch(showMessage({
                    message         : 'Failed to delete CABINET data',
                    autoHideDuration: 2000,
                    anchorOrigin    : {
                        vertical  : 'top',
                        horizontal: 'right'
                    },variant : 'error'
                }));
            });    
        })
        .catch(err => {
            dispatch(uploadLoading(false));
            dispatch(showMessage({
                message         : 'AWS S3 DELETE CABINET ERROR',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));
            return;
        })
    })
}

export function updateCabinet(data, id, guid) {
    var data_gltf = {
        'gltf' : jsonArray(data.gltf)
    };
    var data_string = JSON.stringify(data_gltf);
    var getArray = (data) => {
        var items = '';
        data.forEach((fElement,index) => {
            if(index === 0)
                items += fElement;
            else
                items += ',' + fElement;
        });
        return items;
    }
    var updateData = {
        id: id,
        name: data.cabinet_name,
        long_name: data.cabinet_long_name,
        cabinet_type: data.cabinet_type,
        location_type: data.location_type,
        is_corner: data.is_corner,
        sku_number: data.sku_number,
        cabinet_category_id: data.category_id,
        width: data.width,
        height: data.height,
        length : data.length,
        leftBlindLength: data.leftBlindLength,
        rightBlindLength: data.rightBlindLength,
        comments : data.comments,
        cabinet_door_style_ids :  getArray(data.cabinet_door_style_id),
        thumbnail: jsonArray([data.thumbnail[0]]),
        shape_svg: shapeSVGValue(data.shape_svg),
        outline: shapeSVGValue(data.outline),
        gltf : data_string
    }

    return ((dispatch) => {
        dispatch(uploadLoading(true));
        axios.post(`${API_SERVER_URL}/api/cabinet/update/cabinet`, {
            ...updateData
        })
        .then(res => {
            if(process.env.REACT_APP_MODE === "production") {
                putFiles(data.gltf ? data.gltf.filter(file => file.originFileObj !== undefined) : [], `uploads/Cabinet/${guid + '_' + id}/gltf/`).then(gltf => {
                    putFiles(data.thumbnail.filter(file => file.originFileObj !== undefined), `uploads/Cabinet/${guid + '_' + id}/thumbnail/`).then(thumbnail => {
                        putFiles(data.shape_svg.filter(file => file.originFileObj !== undefined), `uploads/Cabinet/${guid + '_' + id}/shape_svg/`).then(shape_svg => {
                            putFiles(data.outline.filter(file => file.originFileObj !== undefined), `uploads/Cabinet/${guid + '_' + id}/outline/`).then(outline=>{
                                axios.post(`${API_SERVER_URL}/api/base/updateUploadData`, 
                                    {
                                        id: id,
                                        tableName: "cabinet",
                                        data: [ 
                                            {
                                                fieldName: 'gltf',
                                                type: 'multi',
                                                data: [ 
                                                    {
                                                        jsonFieldName: 'gltf',
                                                        data: gltf,
                                                    }
                                                ]
                                            }, 
                                            {
                                                fieldName: 'thumbnail',
                                                type: 'single',
                                                data: thumbnail
                                            },
                                            {
                                                fieldName: 'shape_svg',
                                                type: 'single',
                                                data: shape_svg
                                            },
                                            {
                                                fieldName: 'outline',
                                                type: 'single',
                                                data: outline
                                            },
                                        ]
                                    }
                                )
                                .then(response => {
                                    const {success} = response.data;
                                    dispatch(uploadLoading(false));
                                    if(success === true) {
                                        dispatch(showMessage({
                                            message         : 'Successfully Updated',
                                            autoHideDuration: 2000,
                                            anchorOrigin    : {
                                                vertical  : 'top',
                                                horizontal: 'right'
                                            },variant : 'success'
                                        }));
                                        Promise.all([
                                            dispatch({
                                                type: UPDATE_CABINET
                                            })
                                        ]).then(() => dispatch(getCabinet()))
                                    } else {
                                        console.log(success);
                                    }
                                })
                                .catch(err => {
                                    console.log("failed to save uploaded data in update action", err);
                                    throw err;
                                })
                            })
                        })
                        .catch(err => {
                            console.log("failed to put cabinet shape(svg). ", err);
                            return;
                        })
                    })
                    .catch(err => {
                        console.log("failed to put cabinet thumbnail. ", err);
                        return;
                    })
                })
                .catch(err => {
                    console.log("failed to upload cabinet gltfs. ", err);
                    return;
                })
            }
            else {
                if(data.thumbnail[0].originFileObj !== undefined || canUpload(data.shape_svg) === true || canUpload(data.gltf) === true || canUpload(data.outline) === true) {
                    const formData = new FormData();
                    formData.append("guid" , guid);
                    formData.append("id" , id);
                    if(data.thumbnail[0].originFileObj !== undefined) {
                        formData.append("thumbnail", data.thumbnail[0].originFileObj);
                    }
                    if(canUpload(data.outline)){
                        formData.append("outline", data.outline[0].originFileObj);
                    }
                    if(canUpload(data.shape_svg)) {
                        data.shape_svg.forEach(fElement => {
                            formData.append("shape_svg", fElement.originFileObj);
                        })
                    }
                    if(canUpload(data.gltf)) {
                        data.gltf.forEach(fElement => {
                            formData.append("gltf",fElement.originFileObj);
                        })
                    }
                    axios.post(`${API_SERVER_URL}/api/cabinet/upload`, formData)
                    .then(response => {
                        const {success} = response.data;
                        dispatch(uploadLoading(false));
                        if(success === true) {
                            axios.post(`${API_SERVER_URL}/uploads/clear`)
                            .then(res => {
                                if(res.data.success === true ){
                                    dispatch(showMessage({
                                        message         : 'Successfully Updated',
                                        autoHideDuration: 2000,
                                        anchorOrigin    : {
                                            vertical  : 'top',
                                            horizontal: 'right'
                                        },variant : 'success'
                                    }));
                                    Promise.all([
                                        dispatch({
                                            type: UPDATE_CABINET
                                        })
                                    ]).then(() => dispatch(getCabinet()))
                                }
                            })
                        }
                    })
                    
                } else{
                    dispatch(uploadLoading(false));
                    dispatch(showMessage({
                        message         : 'Successfully Updated',
                        autoHideDuration: 2000,
                        anchorOrigin    : {
                            vertical  : 'top',
                            horizontal: 'right'
                        },variant : 'success'
                    }));
                    Promise.all([
                        dispatch({
                            type: UPDATE_CABINET
                        })
                    ]).then(() => dispatch(getCabinet()))
                }   
            }
        })
        .catch(err => {
            console.log(err);
            dispatch(uploadLoading(false));
            dispatch(showMessage({
                message         : 'Failed to update CABINET data',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));
        });
        
    })
}

export function setModalData(data) {
    return (dispatch => {
        dispatch({
            type: SET_MODAL_DATA,
            modalData: data,
        })
    })
}

export function validateCabinet(data) {
    const {id, gltfData} = data;

    return (dispatch => {
        axios.post(`${API_SERVER_URL}/api/cabinet/getCabinetDoorStyleDataById`, {
            id : id,
            base: gltfData,
        })
        .then(response => {
            const {success, msg} = response.data;
            if(success === true) {
                // if(msg.length === 0) {
                //     dispatch(showMessage({
                //         message         : 'Success.',
                //         autoHideDuration: 2000,
                //         anchorOrigin    : {
                //             vertical  : 'top',
                //             horizontal: 'right'
                //         },variant : 'success'
                //     }));
                // }
                // else {
                //     console.log(msg);
                // }
                dispatch(setModalData(msg))
            }
        }).catch(err => {
            // dispatch(showMessage({
            //     message         : 'Failed to delete CABINET data',
            //     autoHideDuration: 2000,
            //     anchorOrigin    : {
            //         vertical  : 'top',
            //         horizontal: 'right'
            //     },variant : 'error'
            // }));
        });            
    })
}

export const SET_CABINET_SORT_ORDER = '[CABINET] SET SORT ORDER';
export function setSortOrder(data) {
    return ((dispatch) => {
        dispatch(uploadLoading(true));
        axios.post(`${API_SERVER_URL}/api/base/setSortOrder`, {
            id: data.id,
            sort_order: data.sort_order,
            table_name: 'cabinet'
        })
        .then(response => {
            const {success} = response.data;
            if(success === true) {
                dispatch(uploadLoading(false));
                Promise.all([
                    dispatch({
                        type:SET_CABINET_SORT_ORDER,
                    })  
                ]).then(() => dispatch(getCabinet()))
            } else {
                dispatch(uploadLoading(false));
            }
        })
        .catch(err => {
            dispatch(uploadLoading(false));
            dispatch(showMessage({
                message         : 'Failed to set sort order',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));
            console.log("failed to set sort order in cabinet.", err);
        })
    })
}

export function uploadBuildUp (data) {
    const formData = new FormData();
    formData.append("zip", data[0].originFileObj);
    return ((dispatch) => {
        dispatch(uploadLoading(true));
        axios.post(`${API_SERVER_URL}/api/cabinetDoorStyle/upload_buildup`, formData)
        .then(response => {
            dispatch(uploadLoading(false));
            const {success, errno, errmsg} = response.data;
            if(success === true) {
                dispatch(showMessage({
                    message         : 'Successfully Uploaded',
                    autoHideDuration: 2000,
                    anchorOrigin    : {
                        vertical  : 'top',
                        horizontal: 'right'
                    },variant : 'success'
                }));
            }
            else {
                let errMessage = 'Unknown Error';
                let errType = 'error';

                switch(errno) {
                    case 101:
                    case 102:
                    case 103:
                        errMessage = errmsg;
                        break;
                }
                dispatch(showMessage({
                    message         : errMessage,
                    autoHideDuration: 2000,
                    anchorOrigin    : {
                        vertical  : 'top',
                        horizontal: 'right'
                    },variant : errType
                }));
            }
        }).catch(err => {
            console.log(err);
            dispatch(showMessage({
                message         : 'Failed to Upload',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));
        });
    });
}