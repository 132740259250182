import Project from './Project';
import {authRoles} from 'app/auth';
export const ProjectConfig = {
    settings: {
        layout: {
        }
    },
    auth    : authRoles.onlyProject,
    routes  : [
        {
            path     : '/cabinet/project',
            component: Project
        }
    ]
};