import axios from 'axios';
import { API_SERVER_URL } from 'app/main/config';
import { showMessage } from 'app/store/actions/fuse';

export const GET_DEALER = '[DEALER] GET DEALER';
export const DELETE_DEALER = '[DEALER] DELETE DEALER';
export const OPEN_NEW_USER_DIALOG = '[DEALER] OPEN NEW USER DIALOG';
export const CLOSE_NEW_USER_DIALOG = '[DEALER] CLOSE NEW USER DIALOG';
export const OPEN_EDIT_USER_DIALOG = '[DEALER] OPEN EDIT USER DIALOG';
export const CLOSE_EDIT_USER_DIALOG = '[DEALER] CLOSE EDIT USER DIALOG';
export const ADD_USER = '[DEALER] ADD USER';
export const UPDATE_USER = '[DEALER] UPDATE USER';

export function getDealer() {
    return (dispatch => {
        axios.post(`${API_SERVER_URL}/api/dealer/read/dealer`)
            .then(response => {
                const { dealerData, success } = response.data;
                if (success === true) {
                    dispatch({
                        type: GET_DEALER,
                        payload: dealerData,
                    });
                }
            }).catch(err => {
                dispatch(showMessage({
                    message: 'Failed to load DEALER data',
                    autoHideDuration: 2000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }, variant: 'error'
                }));
                console.log("err", err);
            });
    });

}

export function deleteDealer(id) {
    return (dispatch => {
        axios.post(`${API_SERVER_URL}/api/dealer/remove/dealer`, {
            id
        })
            .then(response => {
                const { dealerData, success } = response.data;
                if (success === true) {
                    dispatch(showMessage({
                        message: 'Successfully Deleted',
                        autoHideDuration: 2000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }, variant: 'success'
                    }));
                    dispatch(getDealer());
                }
            }).catch(err => {
                dispatch(showMessage({
                    message: 'Failed to delete DEALER data',
                    autoHideDuration: 2000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }, variant: 'error'
                }));

                console.log("err", err);
            });
    });
}

export function openNewUserDialog() {
    return {
        type: OPEN_NEW_USER_DIALOG
    }
}

export function closeNewUserDialog() {
    return {
        type: CLOSE_NEW_USER_DIALOG
    }
}

export function openEditUserDialog(data) {
    return {
        type: OPEN_EDIT_USER_DIALOG,
        data
    }
}

export function closeEditUserDialog() {
    return {
        type: CLOSE_EDIT_USER_DIALOG
    }
}

export function addDealer(data) {
    return dispatch => {
        delete data.password2;
        const request = axios.post(`${API_SERVER_URL}/api/dealer/insert/dealer`, {
            data
        });
        return request.then((response) => {
            dispatch(showMessage({
                message: 'Successfully Inserted',
                autoHideDuration: 2000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }, variant: 'success'
            }));
            dispatch(getDealer());
        }
        ).catch(err => {
            dispatch(showMessage({
                message: 'Failed to add DEALER data',
                autoHideDuration: 2000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }, variant: 'error'
            }));

            console.log("err", err);
        });;

    };
}

export function updateDealer(data) {
    return dispatch => {
        delete data.password2;

        const updatePwdReq = axios.post(`${API_SERVER_URL}/api/dealer/update/pwd`, {oldPassword: data.oldPassword, password: data.password, id: data.id});
        const request = axios.post(`${API_SERVER_URL}/api/dealer/update/dealer`, {data});
        if(data.oldPassword.length) {
            updatePwdReq.then((res => {
                if(res.data.error){
                    dispatch(showMessage({
                        message: res.data.error,
                        autoHideDuration: 2000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }, variant: 'error'
                    }));
                } else {
                    request.then((response) => {
                        if(response.data.success){
                            dispatch(showMessage({
                                message: 'Successfully Updated',
                                autoHideDuration: 2000,
                                anchorOrigin: {
                                    vertical: 'top',
                                    horizontal: 'right'
                                }, variant: 'success'
                            }));
                            dispatch(getDealer());
                        }
                    }).catch(err => console.log(err));
                }
            }));
        } else {
            request.then((response) => {
                dispatch(showMessage({
                    message: 'Successfully Updated',
                    autoHideDuration: 2000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }, variant: 'success'
                }));
                dispatch(getDealer());
            }).catch(err => console.log(err));
        }
    };
}
export function showAlert(message, type) {
    return dispatch => {
        dispatch(showMessage({
            message: message,
            autoHideDuration: 2000,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
            }, variant: type
        }));
    }
}