const settingsConfig = {
    layout          : {
        style : 'layout1', // layout-1 layout-2 layout-3
        config: {} // checkout layout configs at app/fuse-configs/layout-1/Layout1Config.js
    },
    customScrollbars: true,
    theme           : {
        main   : 'greeny',
        navbar : 'greeny',
        toolbar: 'greeny',
        footer : 'greeny'
    }
};

export default settingsConfig;
