import RTALive from './RTALive';
import {authRoles} from 'app/auth';
export const RTALiveConfig = {
    settings: {
        layout: {
        }
    },
    auth    : authRoles.designer,
    routes  : [
        {
            path     : '/cabinet/rtalive',
            component: RTALive
        }
    ]
};