import React, { Component } from 'react';
import * as Actions from './store/actions';
import * as CabinetActions from '../cabinet/store/actions';
import { showMessage } from 'app/store/actions';
import reducer from './store/reducers';
import cabinetReducer from '../cabinet/store/reducers';
import { withRouter } from 'react-router-dom';
import withReducer from 'app/store/withReducer';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import green from '@material-ui/core/colors/green';
import { FusePageCarded, FuseAnimate } from '@fuse';
import { Tooltip, Chip } from '@material-ui/core';
import { Table, Typography, Form, Row, Spin, Button, Input, Icon } from 'antd';
import RTALiveImportDrawer from './RTALiveImportDrawer';
import { withStyles } from '@material-ui/core/styles';
import { CsvBuilder } from 'filefy';
import Axios from 'axios';
import {
    API_SERVER_URL
} from 'app/main/config';
import moment from 'moment';

const { Title } = Typography;

const styles = theme => ({

    button: {
        marginBottom: "14px",
    },
    list: {
        width: 750,
    },
    list1: {
        width: 700,
    },

    greenAvatar: {
        margin: 10,
        color: green[500],
        backgroundColor: '#dee',
        width: 200,
        height: 200,
    },
    bigIcon: {
        width: 150,
        height: 150,
    },
});

const defaultPagination = {
    pageSizeOptions: ['10', '20', '50', '70'],
    showSizeChanger: true,
    size: 'small',
    position: 'top',
}

class RTALive extends Component {

    constructor(props) {
        super(props);
        const value = props.value || {};
        this.state = {
            importVisible: false,
            lastProductPriceUpdatedTime: 0
        };
        props.getRTAlive();
        props.getCabinet();
        props.getDoorColor();
        props.getDoorStyle();
        props.getCabinetDoorStyle();
    }

    toggleImportDrawer = (visible) => {
        this.setState({ importVisible: visible });
    }

    updateProuctPrice = () => {
        Axios.post(`${API_SERVER_URL}/api/magento/updatePrice`, {}).then(_ => {
            let msg;
            if (_.data.success) msg = `Successfully updated at ${moment(_.data.updatedTime).format('YYYY-MM-DD HH:mm:ss')}`
            else msg = `Update failed at ${moment(_.data.updatedTime).format('YYYY-MM-DD HH:mm:ss')}.`;
            this.props.showMessage({
                message: msg,
                autoHideDuration: 2000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                }, variant: 'success'
            });
            this.getLastProductPriceUpdatedTime();
        })
    }

    getLastProductPriceUpdatedTime = () => {
        Axios.post(`${API_SERVER_URL}/api/state/get_state`, { keyName: "lastProductPriceUpdatedTime" })
            .then((res) => {
                this.setState({ lastProductPriceUpdatedTime: res.data[0].value });
            })
            .catch((err) => {
                console.log(err.response)
            })
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={'search...'}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text => (
            text
        ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    exportMismatch() {
        const { cabinet, cabinetDoorStyle, doorColorData, doorStyleData, rtalive } = this.props;
        let csvBuilder = new CsvBuilder("mismatch_data.csv");
        const MAINCOLUMNLABEL = ["FULL SKU" ,"CABINET_SKU", "DOOR_COLOR_SKU", "IS_ASSEMBLED", "WHERE"];
        csvBuilder.setColumns(MAINCOLUMNLABEL);
        let mismatch_data = [];
        cabinet.map(item => {
            let cabinet_sku = item.sku_number;
            if (cabinet_sku.endsWith('-R') || cabinet_sku.endsWith('-L')) cabinet_sku = cabinet_sku.slice(0,-2);
            const org_cabinet_sku = cabinet_sku
            let cdsArr = item.cabinet_door_style_ids.split(',');
            for(let i=0; i< cdsArr.length; i++) {
                let cds = cabinetDoorStyle.filter(el => { return el.id === parseInt(cdsArr[i]) });
                if (cds.length === 0 || cds[0] === undefined) continue;
                cds = cds[0];
                if(cds.door_color_ids === null) {
                    // If cds has no door_color_ids, it would be old cds.
                } else {
                    if(cds.sku) cabinet_sku = cds.sku;
                    else cabinet_sku = org_cabinet_sku
                    const dcArr = cds.door_color_ids.split(',');
                    for (let j = 0; j < dcArr.length; j++) {
                        let doorColor = doorColorData.filter(el => { return el.id === parseInt(dcArr[j]) });
                        if (doorColor.length === 0 || doorColor[0] === undefined || doorColor[0].is_active === 0 || doorColor[0].is_published === 0) {
                            continue;
                        }
                        doorColor = doorColor[0];
                        let door_color_sku = doorColor.sku;
                        let is_assembled = doorStyleData.filter(el => doorColor.door_style_id.split(',').some(it => parseInt(it) == el.id)).reduce((state, cur) => state ? true : cur.door_category_id === 124, false);
                        let full_sku = `${cabinet_sku}-${door_color_sku}${is_assembled?'-Assembled':''}`;
                        !mismatch_data.some(it => it[0] === full_sku) && mismatch_data.push([full_sku, cabinet_sku, door_color_sku, is_assembled]);
                    }
                }
            }
        })

        mismatch_data = mismatch_data.filter(it=> {
            let dpIdx = rtalive.findIndex(el => el.sku == it[0])
            if(dpIdx !== -1)
                rtalive.splice(dpIdx, 1);
            else {
                it.push('DIY');
                return true;
            }
            return false;
        });

        csvBuilder.addRows(mismatch_data);
        csvBuilder.addRows(rtalive.map(it => [it.sku, it.sku_code, it.color_code, it.is_assembled ? true: false, 'rtalive']));

        csvBuilder.exportFile();

        this.props.showMessage({
            message: 'Successfully Exported',
            autoHideDuration: 2000,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
            }, variant: 'success'
        });
    }

    componentDidMount() {
        this.getLastProductPriceUpdatedTime();
    }

    render() {
        const { cds_ids, classes, rtalive } = this.props;

        const isLoading = false;

        const props = {
            name: 'file',
            multiple: true,
        };

        const columns = [
            {
                key: 'sku',
                title: 'SKU Number',
                dataIndex: 'sku',
                width: 100,
                ...this.getColumnSearchProps('sku'),
            },
            {
                key: 'name',
                title: 'Name',
                dataIndex: 'name',
                width: 200,
                ...this.getColumnSearchProps('name'),
                render: (text, record) => (
                    <span style={{ overflowWrap: 'anywhere' }}> {text} </span>
                )
            },
            {
                key: 'description',
                title: 'Description',
                dataIndex: 'description',
                width: 200,
                render: (text, record) => (
                    <span dangerouslySetInnerHTML={{ __html: text }} style={{ overflowWrap: 'anywhere' }} />
                )
            },
            {
                key: 'type',
                title: 'Type',
                dataIndex: 'type',
                width: 100,
                ...this.getColumnSearchProps('type'),
                render: (text, record) => (
                    <Chip
                        label={record.type === 'cabinet' ? "Cabinet" : "Molding"}
                        className={classes.button}
                        color={record.type === 'cabinet' ? "secondary" : "primary"}
                    />
                )
            },
            {
                key: 'product_finish',
                title: 'Product Finish',
                dataIndex: 'product_finish',
                width: 200,
                ...this.getColumnSearchProps('product_finish'),
                render: (text, record) => (
                    <span style={{ overflowWrap: 'anywhere' }}> {text} </span>
                )
            },
            {
                key: 'sku_code',
                title: 'Cabinet SKU',
                dataIndex: 'sku_code',
                width: 200,
                ...this.getColumnSearchProps('sku_code'),
                render: (text, record) => (
                    <span style={{ overflowWrap: 'anywhere' }}> {text} </span>
                )
            },
            {
                key: 'color_code',
                title: 'Door Color SKU',
                dataIndex: 'color_code',
                width: 200,
                ...this.getColumnSearchProps('color_code'),
                render: (text, record) => (
                    <span style={{ overflowWrap: 'anywhere' }}> {text} </span>
                )
            },
            {
                key: 'is_assembled',
                title: 'Assembled',
                dataIndex: 'is_assembled',
                width: 100,
                render: (text, record) => (
                    <Chip
                        label={record.is_assembled === 1 ? "Yes" : "No"}
                        className={classes.button}
                        color={record.is_assembled === 1 ? "secondary" : "primary"}
                    />
                )
            },
            {
                key: 'regular_price',
                title: 'Regular Price',
                dataIndex: 'regular_price',
                width: 100,
                render: (text, record) => (
                    <span style={{ overflowWrap: 'anywhere' }}> {text} </span>
                )
            },
            {
                key: 'discounted_price',
                title: 'Discounted Price',
                dataIndex: 'discounted_price',
                width: 100,
                render: (text, record) => (
                    <span style={{ overflowWrap: 'anywhere' }}> {text} </span>
                )
            },
            {
                key: 'qty',
                title: 'Qty',
                dataIndex: 'qty',
                width: 100,
                render: (text, record) => (
                    <span style={{ overflowWrap: 'anywhere' }}> {text} </span>
                )
            },
        ]



        return (
            <Spin tip="Loading..." spinning={isLoading}>
                <FusePageCarded
                    classes={{
                        toolbar: "p-0",
                        header: "min-h-72 h-72 sm:h-136 sm:min-h-136"
                    }}
                    header={
                        <div className="flex flex-1 w-full items-center justify-between">
                            <div className="flex flex-col items-start max-w-full">
                                <div className="flex items-center max-w-full">
                                    <div className="flex flex-col min-w-0">
                                        <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                                            <Title level={4} className="text-16 sm:text-20 truncate">
                                            </Title>
                                        </FuseAnimate>
                                    </div>
                                </div>
                            </div>
                            <FuseAnimate animation="transition.slideRightIn" delay={300}>
                                <div>
                                    <Button
                                        className="whitespace-no-wrap"
                                        variant="contained"
                                        onClick={() => this.toggleImportDrawer(true)}
                                    >
                                        Import from CSV
                                    </Button>
                                    {" "}
                                    <Button
                                        className="whitespace-no-wrap"
                                        variant="contained"
                                        onClick={() => this.exportMismatch(true)}
                                    >
                                        Export Mismatch
                                    </Button>
                                    {" "}
                                    <Tooltip title={`Last Updated: ${moment(new Date(parseInt(this.state.lastProductPriceUpdatedTime))).format('YYYY-MM-DD HH:mm')}`} placement="bottom">
                                        <Button
                                            className="whitespace-no-wrap"
                                            variant="contained"
                                            onClick={() => this.updateProuctPrice()}
                                        >
                                            Manual Update
                                        </Button>
                                    </Tooltip>
                                </div>
                            </FuseAnimate>
                        </div>

                    }
                    contentToolbar={
                        <Title level={4} style={{ marginLeft: "25px" }} className="text-16 sm:text-20 truncate" classes={{ root: "w-full h-64" }}>
                            {"RTALive"}
                        </Title>
                    }
                    content={
                        <div className="p-24">
                            <Row>
                                <Table
                                    bordered
                                    rowKey="id"
                                    className="mtable"
                                    pagination={defaultPagination}
                                    columns={columns}
                                    dataSource={rtalive}
                                    scroll={{ x: 3450, y: 500 }}
                                />
                                <RTALiveImportDrawer
                                    anchor="right"
                                    open={this.state.importVisible}
                                    variant="temporary"
                                    onClose={() => this.toggleImportDrawer(false)}
                                    drawerClasses={{ paper: classes.list }}
                                    importProps={props}
                                    importCSV={(data) => this.props.importCSV(data)}
                                />
                            </Row>
                        </div>
                    }
                />
            </Spin>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        showMessage,
        getRTAlive: Actions.getRTAlive,
        importCSV: Actions.importCSV,
        getCabinet: CabinetActions.getCabinet,
        getDoorStyle: CabinetActions.getDoorStyle,
        getDoorColor: CabinetActions.getDoorColor,
        getCabinetDoorStyle: CabinetActions.getCabinetDoorStyle,
    }, dispatch);
}

function mapStateToProps({ rtalive, fuse, cabinet }) {
    return {
        rtalive: rtalive.rtalive.rtalive,
        cabinet: cabinet.cabinet.cabinet,
        cabinetDoorStyle    : cabinet.cabinetDoorStyle.cabinetDoorStyle,
        doorColorData       : cabinet.cabinetDoorStyle.doorColor,
        doorStyleData       : cabinet.cabinetDoorStyle.doorStyle,
    }
}
const RTALiveComponent = Form.create()(RTALive);

export default withReducer('cabinet', cabinetReducer)(withReducer('rtalive', reducer)(withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(RTALiveComponent)))));