import {combineReducers} from 'redux';
import cabinet from './cabinet.reducer';
import cabinetDoorStyle from './cabinetdoorstyle.reducer';

const reducer = combineReducers({
    cabinet,
    cabinetDoorStyle,
});

export default reducer;
