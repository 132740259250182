import {authRoles} from 'app/auth';
const navigationConfig = [
    {
        'id'      : 'applications',
        'title'   : '',
        'type'    : 'group',
        'icon'    : 'apps',
        'url'     : '/applications',
        'children': [
            {
                'id'   : 'dealer',
                'title': 'Dealer Instance',
                'type' : 'item',
                'icon' : 'group',
                'auth' : authRoles.designer,
                'url'  : '/dealer'
            },
            {
                'id'   : 'catalog',
                'title': 'Dealer Catalog',
                'type' : 'item',
                'icon' : 'group',
                'auth' : authRoles.designer,
                'url'  : '/catalog'
            },
            {
                'id'   : 'manufacturer-catalog',
                'title': 'Manufacturer Catalog',
                'type' : 'collapse',
                'icon' : 'group',
                'auth' : authRoles.designer,
                'children'  : [
                    {
                        'id'   : 'manufacturer',
                        'title': 'Manufacturer',
                        'type' : 'item',
                        'icon' : 'group',
                        'auth' : authRoles.designer,
                        'url'  : '/manufacturer'
                    },
                    {
                        'id'   : 'collection',
                        'title': 'Collection',
                        'type' : 'item',
                        'icon' : 'collections',
                        'auth' : authRoles.designer,
                        'url'  : '/collection'
                    },
                    {
                        'id'   : 'door-category',
                        'title': 'Door Category',
                        'type' : 'item',
                        'icon' : 'category',
                        'auth' : authRoles.designer,
                        'url'  : '/door/category'
                    },
                    {
                        'id'   : 'door-style',
                        'title': 'Door Style',
                        'type' : 'item',
                        'icon' : 'style',
                        'auth' : authRoles.designer,
                        'url'  : '/door/style'
                    },
                    {
                        'id'   : 'doorcolorfamily',
                        'title': 'Door Color Family',
                        'type' : 'item',
                        'icon' : 'category',
                        auth : authRoles.superDesigner,
                        'url'  : '/doorcolorfamily'
                    },
                    {
                        'id'   : 'door-color',
                        'title': 'Door Color',
                        'type' : 'item',
                        'icon' : 'colorize',
                        'auth' : authRoles.designer,
                        'url'  : '/door/color'
                    },
                    {
                        'id'   : 'cabinet-category',
                        'title': 'Cabinet Category',
                        'type' : 'item',
                        'icon' : 'category',
                        'auth' : authRoles.designer,
                        'url'  : '/cabinet/category'
                    },
                    {
                        'id'   : 'cabinet-cabinet',
                        'title': 'Cabinet',
                        'type' : 'item',
                        'icon' : 'table_chart',
                        'auth' : authRoles.designer,
                        'url'  : '/cabinet/cabinet'
                    },
                ]
            },

            {
                'id'   : 'appliance-category',
                'title': 'Appliance Category',
                'type' : 'item',
                'icon' : 'style',
                'auth' : authRoles.designer,
                'url'  : '/appliance/category'
            },
            {
                'id'   : 'appliance',
                'title': 'Appliance',
                'type' : 'item',
                'icon' : 'style',
                'auth' : authRoles.designer,
                'url'  : '/appliance/appliance'
            },
            {
                'id'   : 'finishing touch',
                'title': 'Finishing Touch',
                'type'    : 'collapse',
                'icon'    : 'dashboard',
                'auth' : authRoles.designer,
                'children': [
                    {
                        'id'   : 'door-handle',
                        'title': 'Door Handle',
                        'type' : 'item',
                        'icon' : 'looks',
                        'url'  : '/door/handle'
                    },
                    {
                        'id'   : 'counter-top',
                        'title': 'Counter Top',
                        'type' : 'item',
                        'icon' : 'tab',
                        'url'  : '/counter/top'
                    },
                    {
                        'id'   : 'backsplash',
                        'title': 'Backsplash',
                        'type' : 'item',
                        'icon' : 'kitchen',
                        'url'  : '/finishing_touch/backsplash'
                    },
                    {
                        'id'   : 'wall color',
                        'title': 'Wall Color',
                        'type' : 'item',
                        'icon' : 'palette',
                        'url'  : '/finishing_touch/wall_color'
                    },
                    {
                        'id'   : 'floor styles',
                        'title': 'Floor Styles',
                        'type' : 'item',
                        'icon' : 'view_comfy',
                        'url'  : '/finishing_touch/floor_styles'
                    },
                    {
                        'id'   : 'lighting',
                        'title': 'Lighting',
                        'icon' : 'wb_incandescent',
                        'type' : 'item',
                        'url'  : '/finishing_touch/lighting'
                    },
                    {
                        'id'   : 'furnishing',
                        'title': 'Furnishing',
                        'type' : 'item',
                        'icon' : 'devices',
                        'url'  : '/finishing_touch/furnishing'
                    }
                ]
            },
            {
                'id'   : 'cabinet-rtalive',
                'title': 'RTALive',
                'type' : 'item',
                'icon' : 'table_chart',
                'auth' : authRoles.designer,
                'url'  : '/cabinet/rtalive'
            },
            {
                'id'   : 'project',
                'title': 'Project Menu',
                'type' : 'item',
                'icon' : 'table_chart',
                auth : authRoles.onlyProject,
                'url'  : '/cabinet/project'
            },
            {
                'id'   : 'user',
                'title': 'User',
                'type' : 'item',
                'icon' : 'person',
                auth : authRoles.superDesigner,
                'url'  : '/user'
            },
            {
                'id'   : 'update',
                'title': 'Update',
                'type' : 'item',
                'icon' : 'sync',
                auth : authRoles.superDesigner,
                'url'  : '/update'
            },
            
            
           
        ]
    }
];

export default navigationConfig;
