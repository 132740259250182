import * as Actions from '../actions';
//import _ from '@lodash';

const initialState = {
    collectionData: [],
    manufacturerData:[],
    userDialog     : {
        type : 'new',
        props: {
            open: false
        },
        data : null
    }
};

const collectionReducer = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.GET_COLLECTION:
        {
            return {
                ...state,
                collectionData: action.payload,
            }   
        }
        case Actions.GET_MANUFACTURER:
        {
            return {
                ...state,
                manufacturerData: action.payload,
            }   
        }
        case Actions.DELETE_COLLECTION:
        {
            return {
                ...state,
                collectionData: action.payload,
            }   
        }
        case Actions.OPEN_NEW_USER_DIALOG:
            {
                return {
                    ...state,
                    userDialog: {
                        type : 'new',
                        props: {
                            open: true
                        },
                        data : null
                    }
                };
            }
            case Actions.CLOSE_NEW_USER_DIALOG:
            {
                return {
                    ...state,
                    userDialog: {
                        type : 'new',
                        props: {
                            open: false
                        },
                        data : null
                    }
                };
            }
            case Actions.OPEN_EDIT_USER_DIALOG:
            {
                return {
                    ...state,
                    userDialog: {
                        type : 'edit',
                        props: {
                            open: true
                        },
                        data : action.data
                    }
                };
            }
            case Actions.CLOSE_EDIT_USER_DIALOG:
            {
                return {
                    ...state,
                    userDialog: {
                        type : 'edit',
                        props: {
                            open: false
                        },
                        data : null
                    }
                };
            }
            case Actions.REMOVE_USER_DIALOG:
            {
                return state;
            }
        default:
        {
            return state;
        }
    }
};

export default collectionReducer;
