import React, { Component } from 'react';
import * as Actions from './store/actions';
import { showMessage } from 'app/store/actions';
import reducer from './store/reducers';
import { withRouter } from 'react-router-dom';
import withReducer from 'app/store/withReducer';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import green from '@material-ui/core/colors/green';
import { FusePageCarded, FuseAnimate } from '@fuse';
import { Chip } from '@material-ui/core';
import { Table, Typography, Form, Row, Button } from 'antd';
import { withStyles } from '@material-ui/core/styles';
import Axios from 'axios';
import {
    API_SERVER_URL
} from 'app/main/config';
import moment from 'moment';
import { SyncOutlined } from '@material-ui/icons';
import { InfoOutlined } from '@material-ui/icons';

const { Title } = Typography;
const STATE_STOP = "stop";
const STATE_PROGRESS = "progress";
const RTALive = "RTALive";

const styles = theme => ({

    button: {
        marginBottom: "14px",
    },
    list: {
        width: 750,
    },
    list1: {
        width: 700,
    },

    greenAvatar: {
        margin: 10,
        color: green[500],
        backgroundColor: '#dee',
        width: 200,
        height: 200,
    },
    bigIcon: {
        width: 150,
        height: 150,
    },
});

class Update extends Component {

    constructor(props) {
        super(props);
        const value = props.value || {};
        this.state = {
            updateList: []
        };
    }


    updateProuctPrice = () => {
        Axios.post(`${API_SERVER_URL}/api/magento/updatePrice`, {}).then(_ => {
            let msg;
            if (_.data.success) {
                if (_.data.progress === STATE_STOP) {
                    msg = `Successfully started at ${moment(_.data.startTime).format('YYYY-MM-DD HH:mm:ss')}.`
                    let temp = this.state.updateList;
                    temp.map(it => {
                        if (it.name === _.data.name) {
                            it.progress = STATE_PROGRESS;
                            it.startTime = _.data.startTime;
                        }
                    })
                    this.setState({ updateList: temp })
                }
                else
                    msg = `In progress, started at ${moment(parseInt(_.data.startTime)).format('YYYY-MM-DD HH:mm:ss')}.`;
            }
            else msg = `Update failed`;
            this.props.showMessage({
                message: msg,
                autoHideDuration: 2000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                }, variant: 'success'
            });
        })
    }

    getUpdateList = () => {
        Axios.get(`${API_SERVER_URL}/api/update/get/list`)
            .then((res) => {
                console.log(res.data);
                this.setState({ updateList: res.data });
            })
            .catch((err) => {
                console.log(err)
            })
    }

    clickUpdateHandler = (type) => {
        if (type == 'RTALive') {
            this.updateProuctPrice();
        }
    }

    componentDidMount() {
        this.getUpdateList();
    }

    render() {
        const { classes } = this.props;

        const { updateList } = this.state;

        const columns = [
            {
                key: 'name',
                title: 'Name',
                dataIndex: 'name',
                width: 150,
            },
            {
                key: 'lastUpdatedTime',
                title: 'LastUpdatedTime',
                dataIndex: 'lastUpdatedTime',
                width: 150,
                render: (text, record) => (
                    <span >{moment(parseInt(text)).format('YYYY-MM-DD HH:mm:ss')}</span>
                )
            },
            {
                key: 'progress',
                title: 'Progress',
                dataIndex: 'progress',
                width: 300,
                render: (text, record) => (
                    <Chip
                        label={record.progress === STATE_PROGRESS ? `In progress, started at ${moment(parseInt(record.startTime)).format('YYYY-MM-DD HH:mm:ss')}` : "Stopped"}
                        className={classes.button}
                        color={record.progress === STATE_PROGRESS ? "primary" : "secondary"}
                    />
                )
            },
            {
                key: 'status',
                title: 'Status',
                dataIndex: 'status',
                width: 100,
                render: (text, record) => (
                    <Chip
                        label={record.status === 1 ? "OK" : "Failed"}
                        className={classes.button}
                        color={record.status === 1 ? "primary" : "secondary"}
                    />
                )
            },
            {
                key: 'operation',
                title: 'Operation',
                dataIndex: 'operation',
                width: 200,
                render: (_, record) => (
                    <>
                        <Button
                            shape="circle"
                            style={{marginRight: 10}}
                            onClick={() => this.clickUpdateHandler(record.name)}
                        ><SyncOutlined style={{ fontSize: "30px" }} /></Button>
                        <Button
                            shape="circle"
                            onClick={() => this.getUpdateList()}
                        ><InfoOutlined style={{ fontSize: "30px" }} /></Button>
                    </>
                )
            }
        ]

        return (
            <FusePageCarded
                classes={{
                    toolbar: "p-0",
                    header: "min-h-72 h-72 sm:h-136 sm:min-h-136"
                }}
                header={
                    <div className="flex flex-1 w-full items-center justify-between">
                        <div className="flex flex-col items-start max-w-full">
                            <div className="flex items-center max-w-full">
                                <div className="flex flex-col min-w-0">
                                    <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                                        <Title level={4} className="text-16 sm:text-20 truncate">
                                        </Title>
                                    </FuseAnimate>
                                </div>
                            </div>
                        </div>
                    </div>

                }
                contentToolbar={
                    <Title level={4} style={{ marginLeft: "25px" }} className="text-16 sm:text-20 truncate" classes={{ root: "w-full h-64" }}>
                        {"Manual Update"}
                    </Title>
                }
                content={
                    <div className="p-24">
                        <Row>
                            <Table
                                bordered
                                rowKey="id"
                                className="mtable"
                                columns={columns}
                                dataSource={updateList}
                                scroll={{ x: 1000, y: 500 }}
                            />
                        </Row>
                    </div>
                }
            />
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        showMessage,
        // getRTAlive: Actions.getRTAlive,
    }, dispatch);
}

function mapStateToProps({ update, fuse }) {
    return {
        update: update
    }
}
const UpdateComponent = Form.create()(Update);

export default withReducer('update', reducer)(withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(UpdateComponent))));