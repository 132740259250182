import Dealer from './Dealer';
import {authRoles} from 'app/auth';
export const DealerConfig = {
    settings: {
        layout: {
        }
    },
    auth    : authRoles.designer,
    routes  : [
        {
            path     : '/dealer',
            component: Dealer
        }
    ]
};