import React, {Component} from 'react';
import * as Actions from './store/actions';
import reducer from './store/reducers';
import {withRouter} from 'react-router-dom';
import withReducer from 'app/store/withReducer';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {FusePageCarded, FuseAnimate} from '@fuse';
import {IconButton, Button, Drawer, Divider} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { withStyles } from '@material-ui/core/styles';
import {Table, Typography, Input, Form, Modal, Spin, Row, Col} from 'antd';

var ColorPicker = require('rc-color-picker');
const { Title } = Typography;
const { confirm } = Modal;
const FormItem = Form.Item;
const formItemLayout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 12 },
};
const defaultPagination = {
    pageSizeOptions: ['10', '20', '50', '70'],
    showSizeChanger: true,
    size: 'small',
    position: 'top',
}
const styles = theme => ({
    button: {
        marginTop: "-12px",
    },
    input: {
        display: 'none',
    },
    list: {
        width: 500,
    },
});

class WallColor extends Component {
   
    constructor(props) {
        super(props);
        this.state = {
            name      : '',
            color     : '#000000',
            visible   : false,
            type      : "new",
            id        : null,
        };
        props.getWallColor();
       
    }

    toggleDrawer = (type, record, open) => () => {
        if(type === "new") {
            this.setState({
                id        : null,
                name      : '',
                color     : '#000000',
                type      : type,
            })
        }
        else if(type === "edit") {
            this.setState({
                name: record.name,
                type            : type,
                id              : record.id,
                guid            : record.guid,
                color : record.color
            })
        }
        this.setState({
          visible: open,
        });
    };

    // canBeSubmitted()
    // {
    //     const {name, thumbnail, gltf, type} = this.state;
    //     return type==="new" ? (name.length > 0 && thumbnail.length > 0 && gltf.length > 0) : (name.length > 0);
    // }

    handleSubmit = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
              const {type, id} = this.state;
              this.setState({visible: false});
                if(type === "new") {
                    this.props.addWallColor({...this.state});
                }
                if(type === "edit" && id !== null) {
                    this.props.updateWallColor({...this.state}, id, this.state.guid);
                }
                
            }
            
          });
    }

    handleDeleteEvent = (record) => {
        const {deleteWallColor} = this.props;
        confirm({
            title: 'Do you want to delete this Item?',
            onOk() {
                deleteWallColor(record)
            },
            onCancel() {},
        });
    }

    normFile = e => {
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
    }

    moveAction = (type, record) => () => {
        const {wallColor} = this.props;
        const index = wallColor.findIndex( el => el.id === record.id);
        let swapIndex = -1; 
        if (type === "up" && index !== 0 ) {
            swapIndex = index - 1;
        }
        else if (type === "down" && index !== wallColor.length - 1) {
            swapIndex = index + 1;
        }
        if(swapIndex !== -1) {
            this.props.setSortOrder({id: wallColor[index].id, sort_order: wallColor[swapIndex].sort_order});
            this.props.setSortOrder({id: wallColor[swapIndex].id, sort_order: wallColor[index].sort_order});
        }
    }
      
    render()
    {
        const { classes, wallColor, isLoading} = this.props;
        const { color, name, visible, type} = this.state;
        const { getFieldDecorator, setFieldsValue } = this.props.form;

        const columns = [
            {
                key: 'name',
                title: 'Name',
                dataIndex: 'name',
                width: 450,
            },
            {
                key: 'color',
                dataIndex: 'color',
                title: 'Color',
                render: (text, record) => (
                    <Row gutter={12}>
                        <Col span={4}>
                            <span className="rc-color-picker-trigger" style={{backgroundColor: text}}></span>
                        </Col>
                        <Col span={20}>
                            <span>{text}</span>
                        </Col>
                    </Row>
                ),
            },
            {
                key: 'action',
                title: 'Action',
                dataIndex: 'id',
                width: 250,
                fixed: 'right',
                render: (text, record) => (
                    <Row>
                        <IconButton className={classes.button} aria-label="ArrowUp" onClick={this.moveAction("up", record)}>
                            <ArrowUpwardIcon />
                        </IconButton>
                        <IconButton className={classes.button} aria-label="ArrowDown" onClick={this.moveAction("down", record)}>
                            <ArrowDownwardIcon />
                        </IconButton>
                        <IconButton className={classes.button} aria-label="Delete" onClick={this.toggleDrawer("edit", record, true)}>
                            <EditIcon />
                        </IconButton>
                        <IconButton className={classes.button} aria-label="Edit" onClick={e => {e.preventDefault(); this.handleDeleteEvent(record);}}>
                            <DeleteIcon />
                        </IconButton>
                    </Row>
                ),
            },
        ];

        return (
            <Spin tip="Loading..." spinning={isLoading}>
                <FusePageCarded
                    classes={{
                        toolbar: "p-0",
                        header : "min-h-72 h-72 sm:h-136 sm:min-h-136"
                    }}
                    header={
                        <div className="flex flex-1 w-full items-center justify-between">
                            <div className="flex flex-col items-start max-w-full">
                                <div className="flex items-center max-w-full">
                                    <div className="flex flex-col min-w-0">
                                        <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                                            <Title level={4} className="text-16 sm:text-20 truncate">
                                            </Title>
                                        </FuseAnimate>
                                    </div>
                                </div>
                            </div>
                            <FuseAnimate animation="transition.slideRightIn" delay={300}>
                                <Button
                                    className="whitespace-no-wrap"
                                    variant="contained"
                                    onClick={this.toggleDrawer("new", null, true)}
                                >
                                    Add
                                </Button>
                            </FuseAnimate>
                        </div>
    
                    }
                    contentToolbar={
                        <Title level={4} style={{marginLeft: "25px"}} className="text-16 sm:text-20 truncate" classes={{root: "w-full h-64"}}>
                            {"Wall Color"}
                        </Title>
                    }
                    content={
                        <div className="p-24">
                            <Row>
                                <Drawer
                                    anchor="right"
                                    open={visible}
                                    variant="temporary"
                                    onClose={this.toggleDrawer(null, null, false)}
                                    classes={{paper: classes.list}}
                                    onRendered={() => {
                                        var data = {
                                            name      : this.state.name,
                                            gltf      : this.state.gltf,
                                            thumbnail : this.state.thumbnail,
                                        }
                                        setFieldsValue({...data})}}
                                >
                                    <div
                                        tabIndex={0}
                                        role="button"
                                    >
                                        <Title level={3} className="pt-16" style={{textAlign: "center"}}>
                                            { type === "new" ? "Add" : "Edit"} Wall Color
                                        </Title>
                                        <Divider />
                                        <Form layout="vertical" style={{marginTop: "20px"}}>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="name"
                                                        {...formItemLayout}
                                                        label="Wall Color Name"
                                                    >
                                                        {getFieldDecorator("name", {
                                                            initialValue: name,
                                                            rules: [ {
                                                                required: true, message: 'Enter Wall Color.',
                                                            }],
                                                        })(
                                                            (<Input
                                                                placeholder="Enter Wall Color Name"
                                                                onChange={(e)=> {
                                                                    this.setState({name: e.target.value});
                                                                }}
                                                            />)
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="colorValue"
                                                        {...formItemLayout}
                                                        label="Color"
                                                    >
                                                        {getFieldDecorator("color", {
                                                            initialValue: color,
                                                        })(
                                                            (<Input
                                                                placeholder="Enter Wall Color"
                                                                disabled
                                                                addonBefore={
                                                                    <div id="colorPicker">
                                                                        <ColorPicker 
                                                                            color={color}
                                                                            getCalendarContainer={() => {
                                                                                return document.getElementById("colorPicker");
                                                                            }}
                                                                            onClose={e => { 
                                                                                this.setState({color: e.color})
                                                                                setFieldsValue({color: e.color})
                                                                            }}
                                                                        />
                                                                    </div>
                                                                }
                                                                onChange={(e)=> {
                                                                    this.setState({color : e.target.value});
                                                                }}
                                                            />)
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                        </Form>  
                                        <div
                                            style={{
                                                position: 'absolute',
                                                left: 0,
                                                bottom: 0,
                                                width: '100%',
                                                borderTop: '1px solid #e9e9e9',
                                                padding: '10px 16px',
                                                background: '#fff',
                                                textAlign: 'right',
                                            }}
                                        >
                                            <Button onClick={this.toggleDrawer(null, null, false)} style={{ marginRight: 8 }}>
                                                Cancel
                                            </Button>
                                            <Button 
                                                onClick={this.handleSubmit} 
                                                variant="contained" 
                                                color="primary" 
                                                // disabled={!this.canBeSubmitted()}
                                            >
                                                {type === "new" ? "ADD" : "UPDATE"}
                                            </Button>
                                        </div>
                                    </div>
                                </Drawer>
                                <Table 
                                    bordered
                                    rowKey="id"
                                    className="mtable"
                                    pagination={defaultPagination}
                                    columns={columns} 
                                    dataSource={wallColor}
                                    indentSize={20}
                                    scroll={{x: 1500, y: 500}}
                                />
                            </Row>
                        </div>
                    }
                />
            </Spin>
        )
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        getWallColor: Actions.getWallColor,
        addWallColor: Actions.addWallColor,
        setSortOrder: Actions.setSortOrder,
        deleteWallColor: Actions.deleteWallColor,
        updateWallColor: Actions.updateWallColor,
    }, dispatch);
}

function mapStateToProps({wallColor, fuse})
{
    return {
        wallColor: wallColor.wallColor.wallColor,
        isLoading:fuse.loading.isLoading
    }
}
const WallColorComponent = Form.create()(WallColor);

export default withReducer('wallColor', reducer)(withStyles(styles, {withTheme: true})(withRouter(connect(mapStateToProps,mapDispatchToProps)(WallColorComponent))));