import React, {Component} from 'react';
import * as Actions from './store/actions';
import reducer from './store/reducers';
import {withRouter} from 'react-router-dom';
import withReducer from 'app/store/withReducer';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {FusePageCarded, FuseAnimate} from '@fuse';
import {IconButton, Button, Drawer, Divider} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { withStyles } from '@material-ui/core/styles';
import * as appActions from 'app/store/actions';
import {Table, Typography, TreeSelect, Input, Upload, Spin, Form, Modal, Row, Col, Tooltip} from 'antd';
import { CsvBuilder } from 'filefy';

const { Title } = Typography;
const { TreeNode } = TreeSelect;
const { confirm } = Modal;
const FormItem = Form.Item;

const formItemLayout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 12 },
};

const defaultPagination = {
    pageSizeOptions: ['10', '20', '50', '70'],
    showSizeChanger: true,
    size: 'small',
    position: 'top',
}

const styles = theme => ({
    button: {
        marginTop: "-12px",
    },
    input: {
        display: 'none',
    },
    list: {
        width: 500,
    },
});

class CabinetCategory extends Component {
   
    constructor(props) {
        super(props);
        this.state = {
            visible         : false,
            type            : "new",
            id              : null,
            fieldData   : {
                name                    : '',
                parent_category_id      : undefined,
                thumbnail               : [],
                shape_svg               : [],
            },
        };
        props.getCabinetCategory();
    }

    toggleDrawer = (type, record, open) => () => {
        if(type === "new") {
            this.setState({
                id          : null,
                fieldData   : {
                    name                    : '',
                    parent_category_id      : undefined,
                    thumbnail               : [],
                    shape_svg               : [],
                },
            })
        }
        else if(type === "edit") {
            this.setState({
                id          : record.id,
                guid        : record.guid,
                fieldData   : {
                    name                    : record.name,
                    parent_category_id      : record.parent_category_id,
                    thumbnail               : [{
                        uid : 1,
                        name: record.thumbnail.split('/').pop(),
                        status: 'done',
                        url : record.thumbnail,
                    }],
                    shape_svg               : record.shape_svg ? [{
                        uid     : 1,
                        name    : record.shape_svg.split('/').pop(),
                        status  : 'done',
                        url     : record.shape_svg,
                    }] : [],
                },
            })
        }
        this.setState({
            type    : type,
            visible : open,
        });
    };

    handleSubmit = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                if(values.thumbnail === undefined) {
                    values.thumbnail = [{
                        name: "",
                    }];
                }
                this.setState({visible: false});
                const {type, id} = this.state;
                if(type === "new") {
                    this.props.addCabinetCategory(values);
                }
                if(type === "edit" && id !== null) {
                    this.props.updateCabinetCategory(values, id, this.state.guid);
                }
            }
        });
    }

    handleDeleteEvent = (record) => {
        const {deleteCabinetCategory} = this.props;
        confirm({
            title: 'Do you want to delete this Item?',
            onOk() {
                deleteCabinetCategory(record);
            },
            onCancel() {},
        });
    }

    normFile = (e) => {
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
    };

    moveAction = (type, record) => () => {
        const {cabinetCategory} = this.props;
        const findDefault = (data) => {
            var result = undefined;
            for(var i = 0; i< data.length; i ++) {
                if( data[i].id === record.id ) {
                    return {item: data[i], data: data};
                }
                if(data[i].children !== undefined) {
                    result = findDefault(data[i].children);
                    if(result !== undefined) {
                        break;
                    }
                }
            }
            return result;
        }
        const {data} = findDefault(cabinetCategory);
        const index = data.findIndex(el => el.id === record.id);
        let swapIndex = -1; 
        if (type === "up" && index !== 0 && data[index-1].parent_category_id === record.parent_category_id) {
            swapIndex = index - 1;
        }
        else if (type === "down" && index !== data.length - 1 && data[index+1].parent_category_id === record.parent_category_id) {
            swapIndex = index + 1;
        }
        if(swapIndex !== -1) {
            this.props.setSortOrder({id: data[index].id, sort_order: data[swapIndex].sort_order});
            this.props.setSortOrder({id: data[swapIndex].id, sort_order: data[index].sort_order});
        }
    }

    render()
    {
        const { classes, cabinetCategory, isLoading } = this.props;
        const { fieldData, visible, type, id } = this.state;
        const { getFieldDecorator, setFieldsValue } = this.props.form;
        console.log(cabinetCategory)

        const uploadButton = (
            <div>
              <AddIcon />
              <div className="ant-upload-text">
                {type==="new" ? "Upload" : "Change & Upload"}
            </div>
            </div>
        );
        const columns = [
            {
                key: 'name',
                title: 'Name',
                dataIndex: 'name',
                width: 300,
            },
            {
                key: 'thumbnail',
                title: 'Thumbnail',
                dataIndex: 'thumbnail',
                colSpan: 2,
                width: 300,
                render: (text, record) => (
                    <Tooltip placement="topLeft" title={text}>
                        <span>{text.slice(0,40) + '...'}</span>
                    </Tooltip>
                )
            },
            {
                key: 'preview',
                dataIndex: 'thumbnail',
                colSpan: 0,
                width: 150,
                render: (text, record) => (
                    text !== "" 
                    ? (
                    <Row className="list__thumbnail">
                        <img src={text} alt="" />
                    </Row>)
                    : ""
                ),
            },
            {
                key: 'shape_svg',
                title: 'Shape(SVG)',
                dataIndex: 'shape_svg',
                colSpan: 2,
                width: 300,
                render: (text, record) => (
                    <Tooltip placement="topLeft" title={text}>
                        <span>{text ? text.slice(0,40) + '...' : '-'}</span>
                    </Tooltip>
                )
            },
            {
                key: 'preview_shape',
                dataIndex: 'shape_svg',
                colSpan: 0,
                width: 150,
                render: (text, record) => (
                    <Row className="list__thumbnail list__svg">
                        {text && <img src={text} alt="" />}
                    </Row>
                ),
            },
            {
                key: 'action',
                title: 'Action',
                dataIndex: 'id',
                width: 250,
                fixed: 'right',
                render: (text, record) => (
                    <Row>
                        <IconButton className={classes.button} aria-label="ArrowUp" onClick={this.moveAction("up", record)}>
                            <ArrowUpwardIcon />
                        </IconButton>
                        <IconButton className={classes.button} aria-label="ArrowDown" onClick={this.moveAction("down", record)}>
                            <ArrowDownwardIcon />
                        </IconButton>
                        <IconButton className={classes.button} aria-label="Delete" onClick={this.toggleDrawer("edit", record, true)}>
                            <EditIcon />
                        </IconButton>
                        <IconButton className={classes.button} aria-label="Edit" onClick={e => {e.preventDefault(); this.handleDeleteEvent(record);}}>
                            <DeleteIcon />
                        </IconButton>
                    </Row>
                ),
            },
        ];
        
        const loop = data => data.map((item) => {
            if (item.children && item.children.length) {
                return <TreeNode key={item.id.toString()} value={item.id} title={item.name} disabled={(type==="edit") && (id!==null) && (item.id === id)}>{loop(item.children)}</TreeNode>;
            }
            else {
                return <TreeNode key={item.id.toString()} value={item.id} title={item.name} disabled={(type==="edit") && (id!==null) && (item.id === id)}/>;
            }
        });
     
        return (
            <Spin tip="Loading..." spinning={isLoading}>
                  <React.Fragment>
                <FusePageCarded
                    classes={{
                        toolbar: "p-0",
                        header : "min-h-72 h-72 sm:h-136 sm:min-h-136"
                    }}
                    header={
                        <div className="flex flex-1 w-full items-center justify-between">
                            <div className="flex flex-col items-start max-w-full">
                                <div className="flex items-center max-w-full">
                                    <div className="flex flex-col min-w-0">
                                        <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                                            <Title level={4} className="text-16 sm:text-20 truncate">
                                            </Title>
                                        </FuseAnimate>
                                    </div>
                                </div>
                            </div>
                            <FuseAnimate animation="transition.slideRightIn" delay={300}>
                                <Button
                                    className="whitespace-no-wrap"
                                    variant="contained"
                                    onClick={this.toggleDrawer("new", null, true)}
                                >
                                    Add
                                </Button>
                            </FuseAnimate>
                        </div>

                    }
                    contentToolbar={
                        <Title level={4} style={{marginLeft: "25px"}} className="text-16 sm:text-20 truncate" classes={{root: "w-full h-64"}}>
                            {"Cabinet Category"}
                        </Title>
                    }
                    content={
                        <div className="p-24">
                            <Row>
                                <Drawer
                                    anchor="right"
                                    open={visible}
                                    variant="temporary"
                                    onClose={this.toggleDrawer(null, null, false)}
                                    classes={{paper: classes.list}}
                                    onRendered={() => {setFieldsValue({...this.state.data})}}
                                >
                                    <div
                                        tabIndex={0}
                                        role="button"
                                    >
                                        <Title level={3} className="pt-16" style={{textAlign: "center"}}>
                                            { type === "new" ? "Add" : "Edit"} Cabinet Category
                                        </Title>
                                        <Divider />
                                        <Form layout="vertical" style={{marginTop: "20px"}}>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="ParentID"
                                                        {...formItemLayout}
                                                        label="Parent Category"
                                                    >
                                                        {getFieldDecorator("parent_category_id", {
                                                            initialValue: fieldData.parent_category_id,
                                                            rules: [{ required: true, message: 'Please select Parent Category!' }],
                                                        })(
                                                            (<TreeSelect
                                                                showSearch
                                                                dropdownStyle={{ zIndex: 10000, maxHeight: 400, overflow: 'auto' }}
                                                                placeholder="Please select"
                                                                treeDefaultExpandAll
                                                                onSelect={ value => {
                                                                    this.setState({fieldData: {...fieldData, parent_category_id: value}});
                                                                }}
                                                            >
                                                                <TreeNode key={"0"} value={0} title={"root"}>
                                                                    {loop(cabinetCategory)}
                                                                </TreeNode>
                                                            </TreeSelect>)
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="CategoryName"
                                                        {...formItemLayout}
                                                        label="Category Name"
                                                    >
                                                        {getFieldDecorator("name", {
                                                            initialValue: fieldData.name,
                                                            rules: [ {
                                                                required: true, message: 'Enter Cabinet Category.',
                                                            }],
                                                        })(
                                                            (<Input
                                                                placeholder="Enter Cabinet Category Name"
                                                                onChange={(e)=> { 
                                                                    this.setState({fieldData: {...fieldData, name: e.target.value}})
                                                                }}
                                                            />)
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            { fieldData.parent_category_id === 0 &&
                                            (<Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="Thumbnail"
                                                        {...formItemLayout}
                                                        label="Thumbnail"
                                                    >
                                                        {getFieldDecorator("thumbnail", {
                                                            initialValue        : fieldData.thumbnail,
                                                            valuePropName       : 'fileList',
                                                            rules: [{ required: true, message: 'Please Upload Thumbnail!' }],
                                                            getValueFromEvent   : this.normFile,
                                                        })(
                                                            ( <Upload
                                                                listType="picture-card"
                                                                className="upload-list-inline"
                                                                onRemove={file => {
                                                                    this.setState({fieldData: {...fieldData, thumbnail: []}})
                                                                }}
                                                                beforeUpload={file => {
                                                                    this.setState({fieldData: {...fieldData, thumbnail: [file]}})
                                                                    return false;
                                                                }}
                                                                >
                                                                {fieldData.thumbnail.length > 0 ? null : uploadButton}
                                                            </Upload>)
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>)
                                            }
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="shape_svg"
                                                        {...formItemLayout}
                                                        label="Shape(SVG)"
                                                    >
                                                        {getFieldDecorator("shape_svg", {
                                                            initialValue: fieldData.shape_svg,
                                                            valuePropName: 'fileList',
                                                            getValueFromEvent: this.normFile,
                                                        })(
                                                            ( <Upload
                                                                listType="picture-card"
                                                                className="upload-list-inline"
                                                                onRemove={file => {
                                                                    this.setState({
                                                                        fieldData:{...fieldData, shape_svg: []},
                                                                    });
                                                                }}
                                                                beforeUpload={(file, fileList) => {
                                                                    this.setState({
                                                                        fieldData:{...fieldData, shape_svg: [file]},
                                                                    });
                                                                    return false;
                                                                }}
                                                                >
                                                                {fieldData.shape_svg.length == 0 && uploadButton}
                                                            </Upload>)
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                        </Form>  
                                        <div
                                            style={{
                                                position: 'absolute',
                                                left: 0,
                                                bottom: 0,
                                                width: '100%',
                                                borderTop: '1px solid #e9e9e9',
                                                padding: '10px 16px',
                                                background: '#fff',
                                                textAlign: 'right',
                                            }}
                                        >
                                            <Button onClick={this.toggleDrawer(null, null, false)} style={{ marginRight: 8 }}>
                                                Cancel
                                            </Button>
                                            <Button 
                                                onClick={this.handleSubmit} 
                                                variant="contained" 
                                                color="primary" 
                                                // disabled={!this.canBeSubmitted()}
                                            >
                                                {type === "new" ? "ADD" : "UPDATE"}
                                            </Button>
                                        </div>
                                    </div>
                                </Drawer>
                                <Table 
                                    bordered
                                    rowKey="id"
                                    className="mtable"
                                    pagination={defaultPagination}
                                    columns={columns} 
                                    dataSource={cabinetCategory}
                                    indentSize={20}
                                    scroll={{x: 1450, y: 500 }}

                                />
                            </Row>
                        </div>
                    }
                />
            </React.Fragment>
            </Spin>
        )
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        getCabinetCategory      : Actions.getCabinetCategory,
        addCabinetCategory      : Actions.addCabinetCategory,
        deleteCabinetCategory   : Actions.deleteCabinetCategory,
        updateCabinetCategory   : Actions.updateCabinetCategory,
        setSortOrder            : Actions.setSortOrder,
        showMessage             : appActions.showMessage,

    }, dispatch);
}

function mapStateToProps({cabinetCategoryApp, fuse})
{
    return {
        cabinetCategory: cabinetCategoryApp.cabinetCategory.cabinetCategory,
        isLoading:fuse.loading.isLoading
    }
}
const CabinetCategoryComponent = Form.create()(CabinetCategory);


export default withReducer('cabinetCategoryApp', reducer)(withStyles(styles, {withTheme: true})(withRouter(connect(mapStateToProps,mapDispatchToProps)(CabinetCategoryComponent))));