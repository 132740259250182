import * as Actions from '../actions';

const initialState = {
    dealerData: [],
    userDialog     : {
        type : 'new',
        props: {
            open: false
        },
        data : null
    }
};

const dealerReducer = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.GET_DEALER:
        {
            return {
                ...state,
                dealerData: action.payload,
            }   
        }
        // case Actions.DELETE_DEALER:
        // {
        //     return {
        //         ...state,
        //         dealerData: action.payload,
        //     }   
        // }
        case Actions.OPEN_NEW_USER_DIALOG:
            {
                return {
                    ...state,
                    userDialog: {
                        type : 'new',
                        props: {
                            open: true
                        },
                        data : null
                    }
                };
            }
        case Actions.CLOSE_NEW_USER_DIALOG:
        {
            return {
                ...state,
                userDialog: {
                    type : 'new',
                    props: {
                        open: false
                    },
                    data : null
                }
            };
        }
        case Actions.OPEN_EDIT_USER_DIALOG:
        {
            return {
                ...state,
                userDialog: {
                    type : 'edit',
                    props: {
                        open: true
                    },
                    data : action.data
                }
            };
        }
        case Actions.CLOSE_EDIT_USER_DIALOG:
        {
            return {
                ...state,
                userDialog: {
                    type : 'edit',
                    props: {
                        open: false
                    },
                    data : null
                }
            };
        }
        default:
        {
            return state;
        }
    }
};

export default dealerReducer;
