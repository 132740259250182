import * as Actions from '../actions';

const initialState = {
    rtalive: [],
};

const cabinetReducer = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.GET_RTALIVE:
        {
            return{
                ...state,
                ...action,
            }
        }
        default:
        {
            return state;
        }
    }
};

export default cabinetReducer;
